'use client'
import React from 'react'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { AnalyticsEvent } from 'shared/types/frontendTypes'
import { logAnalyticsEvent } from 'lib/utils/analytics'

export interface ButtonProps {
  children: JSX.Element | string
  href?: string
  onClick?: () => void
  className?: string
  linkOuterClassName?: string
  disabled?: boolean
  openInNewTab?: boolean
  primary?: boolean
  secondary?: boolean
  type?: 'button' | 'reset' | 'submit'
  analytics?: AnalyticsEvent
}

export default function Button({
  children,
  href,
  onClick,
  className = '',
  linkOuterClassName = '',
  disabled = false,
  openInNewTab = false,
  primary = false,
  secondary = false,
  type = 'button',
  analytics,
}: React.PropsWithChildren<ButtonProps>) {
  const baseStyles =
    'px-4 rounded-[24px] h-[48px] flex items-center transition cursor-pointer'
  const primaryStyles = `${disabled ? 'bg-neutral-500' : 'bg-black hover:bg-neutral-700 active:bg-neutral-800'} text-white  font-normal`
  const secondaryStyles =
    'border border-neutral-400 hover:bg-neutral-100 active:bg-neutral-200 font-normal'

  const ButtonRoot = () => (
    <button
      className={twMerge(
        `${baseStyles} ${primary && primaryStyles} ${
          secondary && secondaryStyles
        }${disabled ? ' pointer-events-none' : ''} ${className}`,
      )}
      aria-disabled={disabled}
      onClick={() => {
        if (disabled) return
        if (analytics) {
          logAnalyticsEvent(analytics.name, analytics.metadata)
        }
        if (onClick) onClick()
      }}
      type={type}
    >
      {children}
    </button>
  )

  if (!href || disabled) return <ButtonRoot />

  if (openInNewTab)
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={`h-auto w-auto no-underline`}
      >
        <ButtonRoot />
      </a>
    )

  return (
    <>
      <Link
        href={href}
        passHref
        className={`h-auto w-auto no-underline ${linkOuterClassName}`}
      >
        <ButtonRoot />
      </Link>
    </>
  )
}
