import { UserID } from 'shared/types/userTypes'
import logger from './logger.client'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  logger.event(`analytics/pageview`, {
    logType: 'analytics',
    metadata: { url },
  })
  if (process.env.NEXT_PUBLIC_ENVIRONMENT.startsWith('development')) {
    return false
  }
  try {
    ;(window as any).gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
    return true
  } catch (error) {
    logger.error('analytics/gtag-pageview-error', {
      logType: 'analytics',
      error,
    })
    return false
  }
}

// https://docs.google.com/document/d/1vV8dJYNIa5-l25cmL0XwKByG5mMmqg_4ThNBVz0H5FU/edit
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
export function logAnalyticsEvent(
  name: string,
  metadata: { [key: string]: any } = {},
): boolean {
  // console.log('logging analytics event', { name, ...metadata })
  logger.event(`analytics/${name}`, {
    logType: 'analytics',
    metadata,
  })
  if (process.env.NEXT_PUBLIC_ENVIRONMENT.startsWith('development')) {
    return false
  }
  try {
    ;(window as any).gtag('event', name, metadata)
    ;(window as any).clarity('event', name)
    return true
  } catch (error) {
    logger.error('analytics/logAnalyticsEvent-error', {
      logType: 'analytics',
      error,
    })
    return false
  }
}

export function logAnalyticsError(
  name: string,
  metadata: { [key: string]: any } = {},
): boolean {
  logger.error(`analytics/${name}`, {
    logType: 'analytics',
    metadata,
  })
  if (process.env.NEXT_PUBLIC_ENVIRONMENT.startsWith('development')) {
    return false
  }
  try {
    ;(window as any).gtag('event', name, metadata)
    ;(window as any).clarity('event', name)
    return true
  } catch (error) {
    console.error('error logging analytics error', { error })
    logger.error('analytics/logAnalyticsError-error', {
      logType: 'analytics',
      error: JSON.stringify(error),
    })
    return false
  }
}

export function setAnalyticsTag(key: string, value: string) {
  try {
    ;(window as any).clarity('set', key, value)
    return true
  } catch (error) {
    logger.error('analytics/setAnalyticsTag-error', {
      logType: 'analytics',
      error,
    })
    return false
  }
}

export function setAnalyticsUserID(userID: UserID) {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT.startsWith('development')) {
    return false
  }
  try {
    ;(window as any).gtag('config', GA_TRACKING_ID, {
      user_id: userID,
    })
    ;(window as any).clarity('set', 'user_id', userID)
    ;(window as any).clarity('identify', userID)
    return true
  } catch (error) {
    logger.error('analytics/setAnalyticsTag-error', {
      logType: 'analytics',
      error,
    })
    return false
  }
}
