import {
  type LogSeverity,
  type LogEntry,
} from '@google-cloud/logging-min/build/src/entry'
import { Logger } from 'shared/types/utilityTypes'
import { sputnikBeacon } from 'shared/utils/api'

// TODO: Make this function also log a google analytics event...
export function event(eventName: string, data: string | object) {
  log({
    event: eventName,
    data,
  })
}

// a function that gets called on the frontend to send logs to the back
const sendLogToServer = (payload: object | string, metadata: LogEntry = {}) => {
  if (typeof window === 'undefined') return false // don't actually send anything if not in the browser
  // TODO add serialization
  if (!('timestamp' in metadata)) metadata.timestamp = new Date()
  sputnikBeacon('/v1/webhook/log/client/web', {
    entries: [{ metadata, payload }],
  })
}

function _logWrapper(severity: LogSeverity) {
  return (
    description: string | object,
    data: object = {},
    metadata: LogEntry = {},
  ) => log({ description, data }, { severity, ...metadata })
}

export async function log(
  payload: object | string,
  metadata: LogEntry = { severity: 'DEFAULT' },
) {
  // TODO: make this function check if we're on the server (SSR), and if so, use slogger instead and specify SSR
  await sendLogToServer(payload, metadata)

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'development-local' ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'development-remote'
  ) {
    const { severity } = metadata
    if (severity === 'DEFAULT') console.log(payload)
    else if (
      severity === 'DEBUG' ||
      severity === 'NOTICE' ||
      severity === 'INFO'
    )
      console.debug(payload)
    else console.error(payload)
  }

  return true
}

const logger: Logger = {
  log,
  event,
  debug: _logWrapper('DEBUG'),
  info: _logWrapper('INFO'),
  notice: _logWrapper('NOTICE'),
  warning: _logWrapper('WARNING'),
  error: _logWrapper('ERROR'),
  critical: _logWrapper('CRITICAL'),
  alert: _logWrapper('ALERT'),
  emergency: _logWrapper('EMERGENCY'),
}

export default logger
