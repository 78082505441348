import { APIErrorInterface } from '../types/utilityTypes'

export interface APIErrorInterfaceWithData extends APIErrorInterface {
  data?: { [key: string]: any }
}

export default class APIError extends Error implements APIErrorInterface {
  code: string // eventually, could be nice to organize all of these into enums
  message: string
  errorID?: string
  data?: { [key: string]: any }

  constructor(params: APIErrorInterface | APIErrorInterfaceWithData)
  constructor(message: string)
  constructor(input: string | APIErrorInterface | APIErrorInterfaceWithData) {
    if (typeof input === 'string') {
      super(input)
      this.message = input
      this.code = 'misc-error'
    } else {
      super(input.message)
      this.message = input.message
      this.code = input.code
      this.errorID = input.errorID
      if ('data' in input) this.data = input.data
    }
  }

  serialize() {
    const serializedError: APIErrorInterface = {
      code: this.code,
      message: this.message,
    }
    if (this.errorID) serializedError.errorID = this.errorID
    return serializedError
  }
}
